import { defaultAppSlug } from "./environment";
import { createChargebeeInstance } from "./instance";
import { preconnectPortalSession } from "./session";

/**
 * Sets up a Chargebee SDK instance.
 *
 * Also pre-connects to a portal session if the user created one on a previous page.
 */
export async function setupChargebee(appSlug = defaultAppSlug) {
  appSlug = localStorage.getItem("sciam:chargebee") ?? appSlug;

  try {
    const instance = createChargebeeInstance(appSlug);
    console.log("[chargebee] [setup] instance created", instance);
    await preconnectPortalSession(instance, appSlug);
    console.log("[chargebee] [setup] complete");
    triggerReady(instance);
  } catch (error) {
    console.error("[chargebee] [setup] error", error);
    triggerError(error);
  }
}

// Here we extract the resolver functions from the `chargebeeReady` promise
// This allows us to call Chargebee APIs without worrying about whether the SDK is ready.
let triggerReady: (value: unknown) => void;
let triggerError: (reason?: any) => void;

/** A promise that resolves when the Chargebee SDK is loaded and ready for use. */
export const chargebeeReady = new Promise((ready, error) => {
  console.log("[chargebee] waiting for ready...");
  triggerReady = ready;
  triggerError = error;
}).then(() => console.log("[chargebee] ready"));

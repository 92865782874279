import type { ChargebeeInstance } from "@chargebee/chargebee-js-types";
import type { PortalSession } from "chargebee";
import Cookies from "js-cookie";

import { fetchWithAuth } from "~features/auth/access-token";

/** The Customer ID associated with the current portal session */
let connectedId: string | null = null;
let currentSession: PortalSession | null = null;

interface ChargebeeCurrentState {
  instance: ChargebeeInstance | null;
  session: PortalSession | null;
}

/**
 * When a user already has a session cookie, preconnect to the portal session during page init.
 *
 * This is only intended to be used during page init and outside of any React contexts.
 *
 * @FIXME: Loading session via cookie is disabled until we can figure out how to
 *            update the cookie when the session status changes.
 */
export async function preconnectPortalSession(
  instance: ChargebeeInstance,
  _appSlug: string,
): Promise<ChargebeeCurrentState | void> {
  const session: PortalSession = null; // getSessionCookie(appSlug); <<< DISABLED FOR NOW

  /**
   * By default, Portal Sessions expire 1 hour after they're requested from the backend.
   * If this session expires and we try to load a portal, Chargebee will show the user a "Token Expired" error.
   * To prevent this, let's say any token 10 seconds before expiration is expired.
   */
  const isExpired = session?.expires_at * 1000 || Infinity < Date.now() + 10 * 1000;

  // If there's no portal session cookie, no instance, or we're already connected, return early.
  if (!session || isExpired || !instance || connectedId) {
    console.log("[chargebee] no need to preconnect");
    return { session: currentSession, instance };
  }
  console.log("[chargebee] [preconnect] preconnecting portal session", session);

  // Pre-connect to the portal session with the session cookie.
  console.log("[chargebee] [preconnect] setting portal session", { instance, session });
  const portal = instance.setPortalSession(async () => session);
  console.log("[chargebee] [preconnect] set portal session", portal);

  // Globally cache the session so we can access it later.
  connectedId = session.customer_id;
  currentSession = session;

  return { instance, session };
}

/**
 * Connect to a Chargebee portal session.
 *
 * If we don't already have a session cookie, this will fetch a new session from the backend after hydration.
 *
 * @NOTE This function is structured weirdly because we have to weave multiple async operations together:
 * 1. We return a promise so we can await the Chargebee instance in useChargebee
 * 2. Chargebee's own API requires we pass a promise to be returned from setPortalSession
 *    I'm not sure why they chose a promise instead of a callback function
 *    or returning an promise themselves.
 */
export function connectPortalSession(
  instance: ChargebeeInstance,
  appSlug: string,
  customerId?: string,
  forceRefresh = false,
): Promise<ChargebeeCurrentState> {
  return new Promise(async (resolve, reject) => {
    // Handle cached sessions
    const alreadyConnected = customerId ? connectedId === customerId : !!connectedId;
    if (alreadyConnected && !forceRefresh) {
      console.log("[chargebee] connectPortalSession already connected");
      return resolve({ instance, session: currentSession });
    }

    console.log("[chargebee] connectPortalSession setting portal session", { instance });
    instance.setPortalSession(async () => {
      console.log("[chargebee] connectPortalSession fetching portal session", {
        appSlug,
        customerId,
      });
      let session: PortalSession;

      try {
        session = await fetchSession(appSlug, customerId);
      } catch (error) {
        // Tell our app that we failed to connect to the portal session.
        reject(error);
        // Rethrow the error so Chargebee can handle it.
        throw error;
      }

      // Schedule the resolution for the next frame so Chargebee finishes initializing.
      // We might be able to remove this requestAnimationFrame call, but first we have
      // to investigate why this callback needs to be async in the first place
      if (session)
        requestAnimationFrame(() => {
          connectedId = session.customer_id;
          currentSession = session;
          console.log("[chargebee] connectPortalSession resolved", session);
          resolve({ instance, session });
        });

      console.log("[chargebee] connected portal session");
      return session;
    });
  });
}

export function disconnectPortalSession(instance: ChargebeeInstance) {
  if (!connectedId) return;
  console.log("[chargebee] disconnectPortalSession");
  instance.closeAll();
  connectedId = null;
}

/**
 * Get the session cookie set by `/auth/chargebee/` based on the App ID, e.g. `sa_cb_session.test`
 */
export const getSessionCookie = (appSlug: string) => {
  const session = Cookies.get("sa_cb_session." + appSlug);

  try {
    if (session) {
      return JSON.parse(window.atob(session)) as PortalSession;
    }
  } catch (error) {
    console.error("[chargebee] Error parsing session cookie", error);
  }

  return null;
};

/**
 * Fetch a new Chargebee portal session from the backend.
 */
const fetchSession = async (cbAppSlug: string, customerId?: string) => {
  const response = await fetchWithAuth("/auth/chargebee/portal/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "X-Sciam": "node",
    },
    body: new URLSearchParams({ cb_app_id: cbAppSlug, customer_id: customerId || "" }),
  });

  if (!response.ok) {
    throw new Error(`[chargebee] Failed to fetch portal session: ${response.statusText}`);
  }

  const data = await response.json();
  return data as PortalSession;
};

import { createContext, useContext, useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import type { ChargebeeInstance, Portal } from "@chargebee/chargebee-js-types";
import type { PortalSession } from "chargebee";

import { useFlags } from "~core/hooks/use-flags";
import { useProvideAccessToken } from "~features/auth/access-token";

// Chargebee setup
import { defaultAppSlug } from "../environment";
import { getChargebeeInstance } from "../instance";
import { connectPortalSession, disconnectPortalSession } from "../session";

// Hooks

// Context providers
import { ChargebeeCheckoutContext, useProvideCheckout } from "./use-provide-checkout";
import { ChargebeePortal, useProvidePortal } from "./use-provide-portal";

/** The Chargebee SDK context */
interface ChargebeeContext {
  /** The Chargebee app slug */
  appSlug: string;
  /** The underlying Chargebee SDK instance */
  instance: ChargebeeInstance | null;
  /** The current portal session object. Will be null until a session is created. */
  session: PortalSession | null;
  /** The Chargebee Portal instance */
  portal: ChargebeePortal;
  /** The Chargebee Checkout instance */
  checkout: ChargebeeCheckoutContext;
  /**
   * Manually establish a portal session and prepare a Chargebee Portal instance.
   *
   * Portals will automatically perform this function when you call `portal.open()`.
   * Use this when you want to preemptively perform backend requests and settle
   * React state before showing the UI.
   */
  connectPortal: () => Promise<ChargebeePortal>;
  /** Close any open Chargebee Portal modals */
  close: () => void;
  /** Log out of the Chargebee Portal */
  logout: () => void;
}
const ChargebeeContext = createContext<ChargebeeContext | null>(null);

export const ProvideChargebee = ({ children }) => {
  const flags = useFlags();
  const appSlug = typeof flags?.chargebee === "string" ? flags.chargebee : defaultAppSlug;

  const { user } = useAuth0();
  const [instance, setInstance] = useState<ChargebeeInstance | null>(null);
  const [session, setSession] = useState<PortalSession | null>(null);

  // This will prompt the Auth0's React SDK to fetch an access token and store
  // it in a promise, thus making auth tokens accessible outside React.
  // https://stackoverflow.com/questions/69011315/how-to-get-auth0-token-from-outside-react-components
  useProvideAccessToken();

  //
  // Chargebee SDK Setup
  //
  useEffect(() => {
    async function connect() {
      const initialInstance = await getChargebeeInstance();
      setInstance(initialInstance);
    }
    connect();

    return () => disconnectPortalSession(instance);
  }, []);

  //
  // Chargebee Self Service Portal
  //
  const [portalInstance, setPortalInstance] = useState<Portal | null>(null);

  // Wire up the Chargebee JS events to React
  const portal = useProvidePortal(instance, setupPortalInstance);
  const checkout = useProvideCheckout(instance);

  /**
   * This function handles prerequisites to executing Portal actions.
   * It's designed to be called on-demand, e.g. calling cb.connect() or cb.portal.open()
   */
  async function setupPortalInstance(customerId?: string) {
    console.log("[useChargebee] getting portal instance");
    const cbInstance = instance || (await getChargebeeInstance());

    if (!user) {
      throw new Error("[useChargebee] Cannot create a Chargebee portal session without a user");
    }

    if (!cbInstance) {
      throw new Error(
        "[useChargebee] Cannot create a Chargebee portal session without an SDK instance",
      );
    }

    const noMatchingSession = customerId ? customerId !== session?.customer_id : !session;
    if (noMatchingSession) {
      console.log("[useChargebee] establishing session");
      connectPortalSession(cbInstance, appSlug, customerId).then(({ session }) =>
        setSession(session),
      );
    }

    console.log("[useChargebee] checking portal instance", portalInstance);
    if (portalInstance) return portalInstance;
    console.log("[useChargebee] using cb instance to create portal", cbInstance);
    const cbPortal = cbInstance.createChargebeePortal();
    console.log("[useChargebee] created portal", cbPortal);
    setPortalInstance(cbPortal);
    return cbPortal;
  }

  //
  // Hook Actions
  //

  // Connect a Chargebee Portal Session. Useful if you want to pre-connect before showing the portal.
  const connectPortal = async (customerId?: string) => {
    await setupPortalInstance(customerId);
    return portal;
  };

  const close = () => {
    if (!instance || !portalInstance) return;
    instance.closeAll();
    setPortalInstance(null);
  };

  const logout = () => {
    if (!instance || !session) return;
    instance.logout();
    disconnectPortalSession(instance);
    setSession(null);
  };

  return (
    <ChargebeeContext.Provider
      value={{
        appSlug,
        instance,
        session,
        portal,
        checkout,
        connectPortal,
        close,
        logout,
      }}
    >
      {children}
    </ChargebeeContext.Provider>
  );
};

/**
 * Chargebee hook to access the Chargebee SDK instance and portal session.
 *
 * @example Open a Chargebee Portal
 * ```jsx
 * const { isLoggedIn, login } = useAuth();
 * const { portal } = useChargebee();
 * const showMyAccountPortal = () => isLoggedIn ? portal.open() : login();
 * ```
 *
 * @example Get cart status
 * ```jsx
 * const { instance } = useChargebee();
 * const cart = instance?.getCart();
 * ```
 */
export const useChargebee = () => {
  const value = useContext(ChargebeeContext);
  return value;
};

export default useChargebee;
